

























































import MNotificationVue from "@/mixins/MNotification.vue";
import { IOption } from "@interface/common.interface";
import { ResponseListMasterType } from "@interface/master.interface";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { masterTypeService } from "@service/master-type.service";
import Vue from "vue";
import vClickOutside from "v-click-outside";

export default Vue.extend({
  name: "CSelectCutting",
  directives: {
    clickOutside: vClickOutside.directive
  },
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes,
    },
  },
  mixins: [
    MNotificationVue,
  ],
  props: {
    value: {
      type: String,
      default: undefined,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dtOpt: [] as IOption[],
      vmInput: "",
      loading: false,
      toggleOpen: false,
      focusInput: false,
    };
  },
  mounted() {
    this.getListCutting();
  },
  methods: {
    onClickOutside(): void {
      if (!this.focusInput) {
        this.toggleOpen = false;
        this.focusInput = false;
      }
    },
    getListMasterType(params: RequestQueryParamsModel): Promise<ResponseListMasterType[]> {
      return masterTypeService.listMaster(params);
    },
    async getListCutting(): Promise<void> {
      try {
        this.loading = true;
        const res = await this.getListMasterType({name: "RFQ_CUTTING"});
        this.dtOpt = res.map(x => { return { key: x.value, value: x.value };});
      } catch (error) {
        this.showErrorMessage("notif_process_fail");
      } finally {
        this.loading = false;
      }
    },
    onSelect(e: string): void {
      const selct = this.$refs.selectCutting as any;
      this.toggleOpen = false;
      this.$emit("input", e);
      this.$emit("on-select", e);
      selct.blur();
    },
    addNewOption(): void {
      this.dtOpt.push({key: this.vmInput, value: this.vmInput});
      this.vmInput = "";
    },
    onfocus(): void {
      this.toggleOpen = true;
    },
    onclickOption(): void {
      const ref = this.$refs.selectCutting as any;
      ref.focus();
      this.toggleOpen = false;
      ref.blur();
    },
    onchange(e): void {
      const ref = this.$refs.selectCutting as any;
      this.$emit("input", e);
      this.toggleOpen = false;
      ref.blur();
    },
  }
});

