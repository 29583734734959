var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-select",
    {
      directives: [
        {
          name: "click-outside",
          rawName: "v-click-outside",
          value: _vm.onClickOutside,
          expression: "onClickOutside"
        }
      ],
      ref: "selectCutting",
      staticStyle: { width: "100%" },
      attrs: {
        placeholder: _vm.$t("lbl_choose"),
        value: _vm.value,
        "allow-clear": "",
        open: _vm.toggleOpen,
        loading: _vm.loading,
        disabled: _vm.disabled,
        "dropdown-match-select-width": false
      },
      on: {
        select: _vm.onSelect,
        change: function(e) {
          return _vm.onchange(e)
        },
        focus: _vm.onfocus
      },
      scopedSlots: _vm._u([
        {
          key: "dropdownRender",
          fn: function(menu) {
            return _c(
              "div",
              {},
              [
                _c("v-nodes", { attrs: { vnodes: menu } }),
                _c("a-divider"),
                _c(
                  "div",
                  { staticClass: "my-1" },
                  [
                    _c("a-input", {
                      attrs: {
                        placeholder: _vm.$t("lbl_type_here"),
                        "allow-clear": ""
                      },
                      on: {
                        focus: function($event) {
                          _vm.focusInput = true
                        },
                        blur: function($event) {
                          _vm.focusInput = false
                        }
                      },
                      model: {
                        value: _vm.vmInput,
                        callback: function($$v) {
                          _vm.vmInput = $$v
                        },
                        expression: "vmInput"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "a-button",
                  {
                    staticStyle: { cursor: "pointer" },
                    attrs: { block: "", icon: "plus" },
                    on: {
                      mousedown: function(e) {
                        return e.preventDefault()
                      },
                      click: _vm.addNewOption
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.$t("lbl_add_option")) + " ")]
                )
              ],
              1
            )
          }
        }
      ])
    },
    _vm._l(_vm.dtOpt, function(item) {
      return _c(
        "a-select-option",
        {
          key: item.key,
          attrs: { value: item.value },
          on: { click: _vm.onclickOption }
        },
        [
          _c(
            "a-tooltip",
            [
              _c("template", { slot: "title" }, [
                _vm._v(" " + _vm._s(item.key || "-") + " ")
              ]),
              _vm._v(" " + _vm._s(item.key || "-") + " ")
            ],
            2
          )
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }